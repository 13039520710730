/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
require('../css/app.css');
require('../css/offres.css');
require('../css/login.css');
require('../css/show_offres.css');
require('../css/postuler.css');


// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
var $ = require('jquery');
require('bootstrap');


$(document).ready(function () {
    $('[data-toggle="popover"]').popover();
});


var mouseX = 0,
    mouseY = 0,
    windowHalfX = window.innerWidth / 2,
    windowHalfY = window.innerHeight / 2,
    SEPARATION = 200,
    AMOUNTX = 10,
    AMOUNTY = 10,
    camera,
    scene,
    renderer;

init();
animate();

function init() {

    var container,
        separation = 100,
        amountX = 50,
        amountY = 50,
        particle;

    container = document.createElement('div');
    container.setAttribute('class', 'anim');
    document.body.appendChild(container);

    scene = new THREE.Scene();

    renderer = new THREE.CanvasRenderer({alpha: true}); // gradient; this can be swapped for WebGLRenderer
    renderer.setSize(window.innerWidth, window.innerHeight);
    container.appendChild(renderer.domElement);

    camera = new THREE.PerspectiveCamera(
        75,
        window.innerWidth / window.innerHeight,
        1,
        10000
    );
    camera.position.z = 100;

    // particles
    var PI2 = Math.PI * 2;
    var material = new THREE.SpriteCanvasMaterial({
        color: 0xffffff, //couleur des points
        program: function (context) {
            context.beginPath();
            context.arc(0, 0, 0.3, 0, PI2, true); //position et taille des points
            context.fill();
        }
    });

    var geometry = new THREE.Geometry();

    for (var i = 0; i < 100; i++) {
        particle = new THREE.Sprite(material);
        particle.position.x = Math.random() * 2 - 1;
        particle.position.y = Math.random() * 2 - 1;
        particle.position.z = Math.random() * 2 - 1;
        particle.position.normalize();
        particle.position.multiplyScalar(Math.random() * 10 + 450);
        particle.scale.x = particle.scale.y = 10;
        scene.add(particle);
        geometry.vertices.push(particle.position);
    }

    // lines
    var line = new THREE.Line(geometry, new THREE.LineBasicMaterial({color: 0xffffff, opacity: 0.5}));  //couleur des lignes
    scene.add(line);


    // mousey
    document.addEventListener('mousemove', onDocumentMouseMove, false); //écouteur d'évènement de la souris
    document.addEventListener('touchstart', onDocumentTouchStart, false);
    document.addEventListener('touchmove', onDocumentTouchMove, false);
    window.addEventListener('resize', onWindowResize, false); // s'adapte à la taille de l'écran


} // end init();


function onWindowResize() {

    windowHalfX = window.innerWidth / 2;
    windowHalfY = window.innerHeight / 2;

    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();

    renderer.setSize(window.innerWidth, window.innerHeight);

}

function onDocumentMouseMove(event) {

    mouseX = event.clientX - windowHalfX;
    mouseY = event.clientY - windowHalfY;

}

function onDocumentTouchStart(event) {

    if (event.touches.length > 1) {

        event.preventDefault();

        mouseX = event.touches[0].pageX - windowHalfX;
        mouseY = event.touches[0].pageY - windowHalfY;

    }
}

function onDocumentTouchMove(event) {

    if (event.touches.length == 1) {

        event.preventDefault();

        mouseX = event.touches[0].pageX - windowHalfX;
        mouseY = event.touches[0].pageY - windowHalfY;

    }
}

function animate() {

    requestAnimationFrame(animate);
    render();

}

function render() {

    camera.position.x += (mouseX - camera.position.x) * .0002;
    camera.position.y += (-mouseY + 200 - camera.position.y) * .0002
    camera.lookAt(scene.position);

    renderer.render(scene, camera);

}


// Bouton upload cv sur la page postuler

var btnUpload = $("#contact_cv"),
    btnOuter = $(".button_outer");
btnUpload.on("change", function(e){
    var ext = btnUpload.val().split('.').pop().toLowerCase();
    if($.inArray(ext, ['pdf']) == -1) {
        $(".error_msg").text("Veuillez selectionner un cv au format pdf");
    } else {
        $(".error_msg").text("");
        $("#uploaded_view").addClass("visible");
        btnOuter.addClass("file_uploading");
        setTimeout(function(){
            btnOuter.addClass("file_uploaded");
        },3000);
        var uploadedFile = URL.createObjectURL(e.target.files[0]);
        setTimeout(function(){
            $("#uploaded_view").append('Votre cv a bien été chargé').addClass("show");
        },3500);
    }
});
$(".file_remove").on("click", function(e){
    $("#uploaded_view").removeClass("show");
    $("#uploaded_view").removeClass("visible");
    $("#uploaded_view").find("img").remove();
    btnOuter.removeClass("file_uploading");
    btnOuter.removeClass("file_uploaded");
});


